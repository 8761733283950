<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular pr-3">
        {{ modalTitle }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid class="pa-6">
      <v-row justify="center" v-if="canSeeBuyMore" class="mb-3">
        <v-btn @click="goToStore()" dark color="app-blue">
          {{ $t('boards.buyMoreBoards') }}
        </v-btn>
      </v-row>
      <v-row v-if="boards.length > 0">
        <v-col
          md="4"
          sm="6"
          cols="12"
          v-for="board of boards"
          :key="board.id"
          class="my-1"
        >
          <board
            :board="board"
            @changeProfileBoard="changeProfileBoard"
          ></board>
        </v-col>
      </v-row>
      <div v-else class="text-center py-4">
        <h1 class="heading app-dark-gray--text font-weight-light">
          {{ $t('boards.noBoardsFound') }}
        </h1>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Board from './Board';
import httpBoardsService from '../../services/boards/boards.http.service';
import { UPDATE_USER_BOARD } from '../../store/users/types';

export default {
  name: 'my-boards-modal',
  components: {
    Board
  },
  props: {
    title: {
      type: String
    },
    buyMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    modalTitle() {
      return this.title || this.$i18n.t('boards.myBoards');
    },
    isBoardsStoreRoute() {
      return (
        this.$route.name === 'store' || this.$route.name === 'boards-store'
      );
    },
    canSeeBuyMore() {
      return this.buyMore && !this.isBoardsStoreRoute;
    }
  },
  data() {
    return {
      boards: []
    };
  },
  created() {
    this.loadBoards();
  },
  methods: {
    async loadBoards() {
      const { result } = await httpBoardsService.index();

      this.mapBoards(result);
    },
    async mapBoards(boards) {
      let currentBoardIndex = -1;
      const { board } = this.currentUser;

      if (board && board.id) {
        currentBoardIndex = boards.findIndex(a => a.id === board.id);
      }

      if (currentBoardIndex > -1) {
        const currentBoard = boards[currentBoardIndex];

        boards.splice(currentBoardIndex, 1);
        currentBoard.isCurrent = true;
        boards.unshift(currentBoard);
      }

      this.boards = boards;
    },
    async changeProfileBoard(board) {
      await this.$store.dispatch(UPDATE_USER_BOARD, board.id);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('boards.successBoardChange')
      });

      this.$emit('close');
    },
    goToStore() {
      this.$emit('close');
      this.$router.push('/store/boards');
    }
  }
};
</script>
