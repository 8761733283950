<template>
  <v-card class="text-center board-item cursor" @click="openBoardModal()" tile>
    <div class="px-2">
      <v-img
        class="white--text"
        height="200px"
        contain
        :src="board.imagePath"
      ></v-img>
    </div>
    <h3 class="text-h5 text-center">
      {{ board.name[currentUser.lang] }}
    </h3>
    <v-card-actions class="mt-2 justify-center">
      <v-chip
        v-if="board.price > 0"
        color="app-blue"
        text-color="white"
        class="px-2"
      >
        <gems-label :gems="board.price" textColor="white---text"></gems-label>
      </v-chip>
      <v-chip v-else color="app-green" text-color="white" class="px-2">
        <gold-label
          :gold="board.goldPrice"
          textColor="white---text"
        ></gold-label>
      </v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfirmModal from '../../shared/ConfirmModal';
import GemsLabel from '../../shared/GemsLabel';
import GoldLabel from '../../shared/GoldLabel';
import BuyGemsModal from '../BuyGemsModal';
import BoardStoreItemModal from './BoardStoreItemModal';
import { UPDATE_USER_BOARD } from '../../../store/users/types';
import boardStoreModalStatus from '../../../enums/board.store.modal.status';

export default {
  name: 'board-store-item',
  components: {
    GemsLabel,
    GoldLabel
  },
  props: {
    board: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  created() {
    this.board.imagePath = require(`../../../assets/images/boards/thumbnails/${this.board.thumbnailPath}`);
  },
  methods: {
    getNewUserStats() {
      const result = {};

      if (this.board.goldPrice > 0) {
        result.gold = this.currentUser.stats.gold - this.board.goldPrice;
      } else if (this.board.price > 0) {
        result.gems = this.currentUser.stats.gems - this.board.price;
      }

      return result;
    },
    async changeCurrentBoard() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('boards.doYouWantToChangeYourCurrentBoardWithNewOne')
      });

      if (!confirmed) return;

      await this.$store.dispatch(UPDATE_USER_BOARD, this.board.id);
    },
    async openBoardModal() {
      const { status } = await this.$root.$modal.show(
        BoardStoreItemModal,
        {
          board: this.board
        },
        {
          width: 600
        }
      );

      if (status === boardStoreModalStatus.buyGems) {
        this.$root.$modal.show(BuyGemsModal);
      } else if (status === boardStoreModalStatus.success) {
        this.changeCurrentBoard();
        this.$emit('removeBoardFromList', this.board.id);
      }
    }
  }
};
</script>
