<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header-small">
      <span class="text-h5 font-weight-regular pr-3">
        {{ board.name[currentUser.lang] }}
      </span>
      <v-spacer></v-spacer>
      <v-btn
        text
        icon
        @click="$emit('close', { status: boardStoreModalStatus.closed })"
        class="mr-0"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-3">
      <div class="mb-3">
        <v-img class="white--text" contain :src="board.imagePath"></v-img>
      </div>
      <p v-if="hasDescription" class="text-center font-weight-regular">
        {{ board.description[currentUser.lang] }}
      </p>
      <v-row justify="center" class="mt-3">
        <v-chip
          v-if="board.price > 0"
          color="app-blue"
          text-color="white"
          class="px-2"
        >
          <gems-label :gems="board.price" textColor="white---text"></gems-label>
        </v-chip>
        <v-chip v-else color="app-green" text-color="white" class="px-2">
          <gold-label
            :gold="board.goldPrice"
            textColor="white---text"
          ></gold-label>
        </v-chip>
      </v-row>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-btn color="app-blue" text dark block :loading="loading" @click="buy">
        {{ $t('store.buyNow') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import boardStoreModalStatus from '../../../enums/board.store.modal.status';
import GemsLabel from '../../shared/GemsLabel';
import GoldLabel from '../../shared/GoldLabel';
import httpService from '../../../services/store/boards.store.http.service';
import { UPDATE_USER_STATS } from '../../../store/users/types';
import userStatsMixin from '../../../mixins/store/user.stats.mixin';

export default {
  name: 'board-store-item-modal',
  components: {
    GemsLabel,
    GoldLabel
  },
  props: {
    board: {
      type: Object,
      required: true
    }
  },
  mixins: [userStatsMixin],
  computed: {
    ...mapGetters(['currentUser']),
    hasDescription() {
      return (
        this.board.description && this.board.description[this.currentUser.lang]
      );
    }
  },
  data() {
    return {
      loading: false,
      boardStoreModalStatus: boardStoreModalStatus
    };
  },
  methods: {
    async processBuy() {
      this.loading = true;
      await httpService.buy(this.board.id);

      this.$store.dispatch(UPDATE_USER_STATS, this.getNewUserStats(this.board));

      this.$notify({
        type: 'success',
        text: this.$root.$t('boards.boardBoughtSuccessfully')
      });

      this.loading = false;
      this.$emit('close', { status: boardStoreModalStatus.success });
    },
    buy() {
      if (this.board.price > this.currentUser.stats.gems) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('boards.notEnoughGemsToBuyBoard')
        });

        this.$emit('close', { status: boardStoreModalStatus.buyGems });
      } else if (this.board.goldPrice > this.currentUser.stats.gold) {
        this.$notify({
          type: 'error',
          text: this.$root.$t('boards.notEnoughGoldToBuyBoard')
        });

        this.$emit('close');
      } else {
        this.processBuy();
      }
    }
  }
};
</script>
