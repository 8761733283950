<template>
  <v-row>
    <v-col cols="12">
      <v-card class="border-top-blue mb-3" tile>
        <v-card-title
          class="text-h5 app-dark-gray--text font-weight-light py-2"
        >
          <v-icon class="pr-2">mdi-view-dashboard</v-icon>
          <span>{{ $t('store.boardsStore') }}</span>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-0"
            dark
            @click="open()"
            color="app-blue"
            :small="$vuetify.breakpoint.xs"
          >
            {{ $t('boards.myBoards') }}
          </v-btn>
        </v-card-title>
      </v-card>
      <boards-store v-if="boards" :boards="boards"></boards-store>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import httpStoreService from '../../services/store/boards.store.http.service';
import BoardsStore from '../../components/store/boards/BoardsStore';
import BoardsModal from '../../components/boards/BoardsModal';
import dailyCronMixin from '../../mixins/daily.cron.mixin';

export default {
  name: 'boards',
  components: {
    BoardsStore
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters(['currentUser'])
  },
  data() {
    return {
      boards: [],
      totalBoards: 0
    };
  },
  async created() {
    await this.loadBoards();
  },
  methods: {
    async loadBoards(params = {}) {
      const { result } = await httpStoreService.index(params);

      this.boards = result.items;
      this.totalBoards = result.totalItems;
    },
    open() {
      this.$root.$modal.show(BoardsModal, null, {
        width: 800
      });
    }
  }
};
</script>
