<template>
  <v-card class="text-center avatar-item" tile :ripple="false">
    <v-img class="white--text" contain :src="boardImg"></v-img>
    <h4 class="mt-2">{{ board.name[currentUser.lang] }}</h4>
    <v-card-actions class="mt-2 justify-center">
      <v-btn
        :disabled="board.isCurrent"
        text
        dark
        block
        color="app-blue"
        @click="changeProfileBoard()"
      >
        <span class="grey--text text--lighten-1" v-if="board.isCurrent">
          {{ $t('common.current') }}
        </span>
        <span v-else>{{ $t('boards.useBoard') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'board',
  props: {
    board: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    boardImg() {
      return require(`@/assets/images/boards/thumbnails/${this.board.thumbnailPath}`);
    }
  },
  methods: {
    async changeProfileBoard() {
      this.$emit('changeProfileBoard', this.board);
    }
  }
};
</script>
