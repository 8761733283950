<template>
  <div class="mt-3">
    <v-row v-if="boards.length > 0">
      <v-col
        md="4"
        sm="6"
        cols="12"
        v-for="board of boards"
        :key="board.id"
        class="my-1"
      >
        <board-store-item
          :board="board"
          @removeBoardFromList="onRemoveBoardFromList"
        ></board-store-item>
      </v-col>
    </v-row>
    <div v-else class="text-center py-4">
      <h1 class="heading app-dark-gray--text font-weight-light">
        {{ $t('boards.noBoardsFound') }}
      </h1>
    </div>
  </div>
</template>

<script>
import BoardStoreItem from './BoardStoreItem';

export default {
  name: 'avatars',
  components: {
    BoardStoreItem
  },
  props: {
    boards: {
      type: Array,
      require: true
    }
  },
  methods: {
    onRemoveBoardFromList(id) {
      const index = this.boards.findIndex(b => b.id === id);

      if (index > -1) {
        this.boards.splice(index, 1);
      }
    }
  }
};
</script>
