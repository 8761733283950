export default {
  methods: {
    getNewUserStats(item) {
      const result = {};

      if (item.price > 0) {
        result.gems = this.currentUser.stats.gems - item.price;
      } else if (item.goldPrice > 0) {
        result.gold = this.currentUser.stats.gold - item.goldPrice;
      }

      return result;
    }
  }
};
